<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="12" style="overflow-x: auto;">
                <zw-table ref="table"
                          tableName="customer-clients"
                          base-table="customer"
                          columnsPrefix="customer.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.title.customer-clients"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template v-slot:top-actions>
                        <zw-model-selector-group v-model="client_id"
                                                 name="customer.assign_client"
                                                 model-type="customer"
                                                 disable-label
                                                 disable-opening
                                                 :callback="customerSelected"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <b-button variant="danger"
                                  @click="unlinkClient(row.item.id)"
                        >{{ $t('common.button.unlink') }}
                        </b-button>
                    </template>
                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'clients',
    components: {ZwModelSelectorGroup},
    mixins: [commonTable],
    props: {
        customerId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            client_id: null,
            saveFilters: true,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Customer', ['getCustomerClientsTable']),
        shown() {
            this.loading = false
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Customer/fetchCustomerClients', 'getCustomerClientsTable');
        },
        ctxAdditionalParams() {
            return {
                'provider_id':  this.customerId
            }
        },
        customerSelected(customer) {
            if (customer && customer.id) {
                console.log('Selected: ',customer)
                this.$store.dispatch('Customer/assignClientToProvider', {
                    'provider_id': this.customerId,
                    'client_id': customer.id
                })
                    .then((response) => {
                        this.client_id = null
                        this.refreshTable()
                    })
            }
        },
        unlinkClient(id) {
            this.$store.dispatch('Customer/unlinkClientFromProvider', {
                'client_id': id
            })
                .then((response) => {
                    this.refreshTable()
                })
        },
        getActions() {
            return []
        },
    }
}
</script>